import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";

import { findEarliestTime } from "utils/datesAndTimes/findEarliestTime";
import { findLatestTime } from "utils/datesAndTimes/findLatestTime";
import { formatAsTime } from "utils/datesAndTimes/formatAsTime";
import { ValidationCallback } from "validation/validate";
import terminology from "terminology.json";

import Key from "./Key";
import MeetingCalendarHeadingBar from "./MeetingCalendarHeadingBar";

import { CalendarWrapper } from "./MeetingCalendar.styles";

export interface MeetingCalendarEvent {
	id: string;
	start: Date;
	end: Date;
	status:
		| "available"
		| "booked"
		| "userbooked"
		| "awaitingmentorconfirmation"
		| "userbusy";
}

export const validateMeetingCalendarEvent: ValidationCallback<
	MeetingCalendarEvent
> = (input, { flag, checkField }) => {
	const { start, end, status } = input;

	checkField("id", { type: "string" });

	if (!start) {
		flag("start", "missing");
	} else if (!(start instanceof Date)) {
		flag("start", "not a date");
	}

	if (!end) {
		flag("end", "missing");
	} else if (!(end instanceof Date)) {
		flag("end", "not a date");
	}

	if (!status) {
		flag("status", "missing");
	} else if (
		![
			"available",
			"booked",
			"userbooked",
			"awaitingmentorconfirmation"
		].includes(status)
	) {
		flag("status", "invalid");
	}
};

export interface MeetingCalendarProps {
	isLoading?: boolean;
	isError?: boolean;
	isClickable?: boolean;
	firstDateLocaltime: { year: number; month: number; date: number };
	weekOffset: number;
	onNavigate: (newWeekOffset: number) => void;
	onHaveNewDateRange?: (start: Date, end: Date) => void;
	events: MeetingCalendarEvent[];
	onEventClick?: (e: MeetingCalendarEvent) => void;
	isSignedIn: boolean;
	displaymode: "compact" | "medium" | "full";
	includeHeadingBar?: boolean;
	titleShort?: React.ReactNode;
	titleLong?: React.ReactNode;
}

export const getStartDateAsString = ({
	firstDateLocaltime,
	weekOffset,
	format
}: Pick<MeetingCalendarProps, "firstDateLocaltime" | "weekOffset"> & {
	format: string;
}) => {
	return moment(firstDateLocaltime)
		.add(weekOffset, "week")
		.format(format);
};

export const getEndDateAsString = ({
	firstDateLocaltime,
	weekOffset,
	format
}: Pick<MeetingCalendarProps, "firstDateLocaltime" | "weekOffset"> & {
	format: string;
}) => {
	return moment(firstDateLocaltime)
		.add(weekOffset + 1, "week")
		.format(format);
};

const MeetingCalendar: React.FunctionComponent<MeetingCalendarProps> = ({
	isLoading = false,
	isError = false,
	isClickable = false,
	firstDateLocaltime,
	weekOffset,
	onNavigate,
	onHaveNewDateRange,
	events,
	onEventClick,
	isSignedIn,
	displaymode,
	includeHeadingBar = true,
	titleShort = "Availability",
	titleLong = `Availability for ${terminology.meetings}`
}) => {
	if (!moment(firstDateLocaltime).isValid()) {
		throw new Error("Start date was not a valid date");
	}

	const start = getStartDateAsString({
		firstDateLocaltime,
		weekOffset,
		format: "YYYY-MM-DD"
	});
	const end = getEndDateAsString({
		firstDateLocaltime,
		weekOffset,
		format: "YYYY-MM-DD"
	});

	useEffect(() => {
		if (onHaveNewDateRange) {
			onHaveNewDateRange(moment(start).toDate(), moment(end).toDate());
		}
	}, [start, end, onHaveNewDateRange]);

	const dayHeaderFormat = {
		weekday: "short" as "short",
		day: "numeric" as "numeric"
	};

	const titleFormat = {
		month: "short" as "short",
		...(displaymode === "compact"
			? {}
			: {
					day: "numeric" as "numeric"
			  }),
		...(displaymode === "medium"
			? {}
			: {
					year: "numeric" as "numeric"
			  })
	};

	const eventsForCalendar = events.map(e => ({
		id: e.id,
		start: e.start,
		end: e.end,
		classNames: [e.status],
		extendedProps: {
			status: e.status
		}
	}));

	const minTime = findEarliestTime(
		eventsForCalendar.map(e =>
			moment(e.start)
				.local()
				.toDate()
		),
		false
	);

	const maxTime = findLatestTime(
		eventsForCalendar.map(e =>
			moment(e.end)
				.local()
				.toDate()
		),
		true
	);

	// Add 1 hour buffer around minTime and maxTime to prevent error in FullCalendar in some situations (e.g. if there is only one event in the calendar)
	const slotMinTime = minTime
		? formatAsTime(minTime.clone().subtract(1, "hour"))
		: undefined;
	const slotMaxTime = maxTime
		? formatAsTime(maxTime.clone().add(1, "hour"))
		: undefined;

	return (
		<>
			{includeHeadingBar ? (
				<MeetingCalendarHeadingBar
					displaymode={displaymode}
					onNavigate={onNavigate}
					weekOffset={weekOffset}
					firstDateLocaltime={firstDateLocaltime}
					titleShort={titleShort}
					titleLong={titleLong}
				/>
			) : null}
			<CalendarWrapper
				isClickable={isClickable}
				isLoading={isLoading}
				isError={isError}
				displaymode={displaymode}
			>
				<FullCalendar
					timeZone="local"
					plugins={[timeGridPlugin]}
					initialView="timeGrid"
					allDaySlot={false}
					{...{
						...(slotMinTime ? { slotMinTime } : {}),
						...(slotMaxTime ? { slotMaxTime } : {})
					}}
					slotDuration="01:00:00"
					height="auto"
					visibleRange={{ start, end }}
					customButtons={{
						customToday: {
							text: "today",
							click: () => onNavigate(0)
						},
						customPrev: {
							text: "◁",
							click: () => onNavigate(Math.max(0, weekOffset - 1))
						},
						customNext: {
							text: "▷",
							click: () => onNavigate(weekOffset + 1)
						}
					}}
					headerToolbar={false}
					footerToolbar={false}
					dayHeaderFormat={dayHeaderFormat}
					titleFormat={titleFormat}
					displayEventTime={false}
					events={eventsForCalendar}
					eventClick={
						isClickable && onEventClick
							? e => {
									if (!e.event.id) {
										throw new Error("No id");
									}
									if (!e.event.start) {
										throw new Error("No start date");
									}
									if (!e.event.end) {
										throw new Error("No end date");
									}
									if (
										!(e.event.extendedProps && e.event.extendedProps.status)
									) {
										throw new Error("No event status");
									}
									if (e.event.extendedProps.status === "available") {
										onEventClick({
											id: e.event.id,
											start: e.event.start,
											end: e.event.end,
											status: e.event.extendedProps.status
										});
									}
							  }
							: undefined
					}
				/>
			</CalendarWrapper>
			<Key isSignedIn={isSignedIn} displaymode={displaymode} />
		</>
	);
};

export default MeetingCalendar;
