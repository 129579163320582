import React, { useEffect, useCallback, useContext } from "react";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";
import { isMeeting } from "./isMeeting";

import RescheduleMeetingWizard from "./RescheduleMeetingWizard";
import { useRescheduleMeetingWizard } from "../useRescheduleMeetingWizard";

interface Props {
	reschedulingMeeting: MeetingsDataFlattened | undefined;
	setReschedulingMeeting: React.Dispatch<
		React.SetStateAction<MeetingsDataFlattened | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	events: (MeetingsDataFlattened | MeetingRequestsData)[] | undefined;
}

const Rescheduling: React.FunctionComponent<Props> = ({
	setReschedulingMeeting,
	reschedulingMeeting,
	isWaiting,
	isLoading,
	isError,
	events
}) => {
	const [, setDashboardContextState] = useContext(DashboardContext);

	const onCloseWizard = useCallback(() => {
		setDashboardContextState(oldState => ({
			...oldState,
			meetingsSectionRefreshNum: new Date().getTime()
		}));
	}, [setDashboardContextState]);

	const mentorId =
		reschedulingMeeting === undefined ? undefined : reschedulingMeeting.hostUid;
	const {
		rescheduleMeetingWizardInProgress,
		startRescheduleMeetingWizard,
		handleEndRescheduleMeetingWizard
	} = useRescheduleMeetingWizard(onCloseWizard, mentorId);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingsDataFlattened>({
		key: "meetingReschedule",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events ? events.filter(isMeeting) : []
		},
		handleFoundItem: useCallback(
			event => {
				setReschedulingMeeting(event);
				stripQueryStringFromAddressBar();
			},
			[setReschedulingMeeting, stripQueryStringFromAddressBar]
		)
	});

	const reschedulingMeetingId = reschedulingMeeting
		? reschedulingMeeting.id
		: undefined;
	useEffect(() => {
		if (reschedulingMeetingId) {
			startRescheduleMeetingWizard();
		}
	}, [reschedulingMeetingId, startRescheduleMeetingWizard]);

	return (
		<RescheduleMeetingWizard
			meeting={reschedulingMeeting}
			isInProgress={rescheduleMeetingWizardInProgress}
			onCancel={handleEndRescheduleMeetingWizard}
			onSent={handleEndRescheduleMeetingWizard}
			onFailed={handleEndRescheduleMeetingWizard}
		/>
	);
};

export default Rescheduling;
