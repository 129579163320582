import React, { useCallback } from "react";

import { CompletedWizardData } from "../BookingWizard";
import MeetingCalendar from "components/MeetingCalendar";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";

function StepChooseTimeSlot({
	wizardData: { mentorId },
	disableProgressToNextStep,
	progressToNextStepNow
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const { availabilityCalendarData } = useAvailabilityCalendarData({
		mentorId
	});
	const onEventClick = useCallback(
		(e: MeetingCalendarEvent) => {
			if (e.status === "available") {
				progressToNextStepNow({
					eventToBook: e
				});
			} else {
				disableProgressToNextStep();
			}
		},
		[disableProgressToNextStep, progressToNextStepNow]
	);

	return (
		<MeetingCalendar
			isClickable
			{...availabilityCalendarData}
			onEventClick={onEventClick}
		/>
	);
}

export default StepChooseTimeSlot;
