import { useState, useCallback, useContext } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";

export const useRescheduleMeetingWizard = (
	onClose: () => void,
	mentorId: string | undefined
) => {
	const {
		update: updateAvailabilityCalendarData
	} = useAvailabilityCalendarData({
		mentorId
	});

	const [
		rescheduleMeetingWizardInProgress,
		setRescheduleMeetingWizardInProgress
	] = useState<boolean>(false);

	const [{ uid: currentUid }] = useContext(AuthContext);

	const startRescheduleMeetingWizard = useCallback(() => {
		if (currentUid === undefined) {
			return;
		}

		setRescheduleMeetingWizardInProgress(true);
	}, [setRescheduleMeetingWizardInProgress, currentUid]);

	const handleEndRescheduleMeetingWizard = useCallback(() => {
		setRescheduleMeetingWizardInProgress(false);
		updateAvailabilityCalendarData();
	}, [setRescheduleMeetingWizardInProgress, updateAvailabilityCalendarData]);

	return {
		rescheduleMeetingWizardInProgress,
		setRescheduleMeetingWizardInProgress,
		startRescheduleMeetingWizard,
		handleEndRescheduleMeetingWizard
	};
};
