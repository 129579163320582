import React, { useCallback } from "react";
import moment from "moment-timezone";

import { WizardData } from "../RescheduleMeetingWizard";
import MeetingCalendar from "components/MeetingCalendar";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";

function StepChooseTimeSlot({
	progressToNextStepNow,
	disableProgressToNextStep,
	wizardData: { mentorId, meeting }
}: ModalWizardStepRenderProps<WizardData>) {
	const { availabilityCalendarData } = useAvailabilityCalendarData({
		mentorId
	});

	const handleEventClick = useCallback(
		(e: MeetingCalendarEvent) => {
			if (!meeting) {
				return;
			}
			if (e.status === "available") {
				const meetingDurationInMs = moment
					.utc(meeting.dateEndsJustBeforeUTC)
					.diff(moment.utc(meeting.dateStartsUTC));
				progressToNextStepNow({
					newSessionEvent: {
						start: e.start,
						end: new Date(e.start.getTime() + meetingDurationInMs)
					}
				});
			} else {
				disableProgressToNextStep();
			}
		},
		[progressToNextStepNow, disableProgressToNextStep, meeting]
	);

	if (!meeting) {
		return null;
	}
	return (
		<MeetingCalendar
			isClickable
			{...availabilityCalendarData}
			onEventClick={handleEventClick}
		/>
	);
}

export default StepChooseTimeSlot;
